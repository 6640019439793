<template>
  <el-main>
    <el-row>
      <el-col :span="4" :offset="2" class="left">
        <el-avatar :src="avatar" :size="90"></el-avatar>
        <div class="user_name">昵称<el-link :underline="false" @click="change_name" class="el-icon-edit" style="margin-left:5px"></el-link></div>

        <el-menu    
          default-active="/user/info"
          class="el-menu-vertical-demo"
          router
        >
          <el-menu-item index="/user/info">
            <i class="el-icon-menu"></i>
            <span slot="title">我的文库</span>
          </el-menu-item>
          <el-menu-item index="order">
            <i class="el-icon-document"></i>
            <span slot="title">我的订单</span>
          </el-menu-item>
          <el-menu-item index="address">
            <i class="el-icon-setting"></i>
            <span slot="title">系统设置</span>
          </el-menu-item>
        </el-menu>
      </el-col>

      <el-col :span="17" class="right">
        <router-view></router-view>
      </el-col>
    </el-row>
  </el-main>
</template>

<script>
import avatar from '@/assets/avatar.png'
export default {
  name: "user",
  data(){
    return{
      avatar,
    }
  },
  methods: {
      change_name(){
          alert("更改名字")
      }
  },
};
</script>

<style scoped>
.el-main {
  background: url("~@/assets/core.png");
  background-color: #f1f3fe;
  background-position: top;
  background-size: contain;
  background-repeat: no-repeat;
  /* width: 100%; */
  height: 800px;
  padding-top: 120px;
}
.el-avatar{
    margin-top: 20px;
}
.user_name{
    margin: 10px;
    color: #3C87FA;
    font-size: 17px;
}
.left {
  border-radius: 10px;
  box-shadow: 1px 1px 5px rgb(180, 175, 175);
  height: 550px;
  background: rgb(255, 255, 255,0.8);
  /* display: flex;
    justify-content: center;
    align-content: center; */
  text-align: center;
}
.right {
  margin-left: 30px;
  border-radius: 10px;
  box-shadow: 1px 1px 5px rgb(180, 175, 175);
  height: 550px;
  background: white;
}
</style>